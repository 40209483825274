<template>
       
            <Card class="card-custom">
              <template #title>
                <Toolbar>
                  <template #start>
                      <h3>
                        {{ $t('custom_head') }}
                      </h3>
                  </template>
                  <template #end>
                        <div>
                          <Button :disabled="loadingItem" :loading="loadingItem" @click="$router.push('/custom-scripts/create')" :label="$t('add_head')"
                          icon="pi pi-plus" class="p-button-sm p-button-success" />
                        </div>
                  </template>
              </Toolbar>
              </template>
              <template #content>
                  <!-- filterDisplay="row" -->
                <DataTable 
                    :value="customScripts" 
                    :rows="10"
                    :scrollable="true"
                    :loading="loadingFlag" 
                    :paginator="true"
                    :rowHover="true"
                      stripedRows 
                      class="p-datatable-sm footer-table">
                      <template #empty>
                        <div class="font-weight-bold">{{ $i18n.t('no_data') }} </div>
                     </template>
                      <!-- <Column field="name" :header="$t('name')" :showFilterMenu="false" :sortable="true">
                        <template #body="{data}">
                                <span>
                                    {{ data.name }}
                                </span>
                        </template>
                      </Column> -->
                      <Column field="type" :header="$t('type')" :showFilterMenu="false">
                        <template #body="{data}">
                                <span class="text-capitalize">
                                    {{ data.type }}
                                </span>
                        </template>
                      </Column>
                      <Column field="code" :header="$t('code')" :showFilterMenu="false" :styles="{'min-width': '30rem'}">
                        <template #body="{data}">
                                <span>
                                    {{ data.code }}
                                </span>
                        </template>
                      </Column>
                      <Column field="locale" :header="$t('locate')" :showFilterMenu="false">
                        <template #body="{data}">
                            <div class="d-flex align-center">
                                <v-icon color="var(--text-color)">
                                    {{getIcon(data.locale)}}
                                </v-icon>
                                <span><{{ data.locale }}></span>
                            </div>
                        </template>
                      </Column>
                      <!-- <Column field="author" :header="$t('author')" :showFilterMenu="false">
                        <template #body="{data}">
                                <v-chip label dense color="var(--red-600)">
                                    <span class="font-weight-bold" style="color: var(--white);">{{ data.author }}</span>
                                </v-chip>
                        </template>
                      </Column> -->
                      <Column field="date" :header="$t('date')" :showFilterMenu="false">
                        <template #body="{data}">
                            {{ new Date(data.created_at).toLocaleString()  }}
                        </template>
                      </Column>
                      <Column field="active" :header="$t('active')" :showFilterMenu="false">
                        <template #body="{data}">
                          <v-switch @change="toToggleScript(data)" v-model="data.active" :loading="data.loadingItem" hide-details color="green accent-4"
                          inset class="mt-0 ml-1 custom-switch "></v-switch>
                        </template>
                      </Column>
                      <Column :exportable="false" :styles="{'min-width':'8rem'}">
                        <template #body="{data}">
                            <div class="d-flex align-center">
                              <Button icon="pi pi-pencil" :disabled="data.loadingItem" :loading="data.loadingItem" class="p-button-sm p-button-rounded p-button-warning mr-2" @click="toEditScript(data)" />
                              <Button icon="pi pi-trash" :disabled="data.loadingItem" :loading="data.loadingItem" class="p-button-sm p-button-rounded p-button-danger" @click="toDeleteScript(data)" />
                          
                            </div>
                          </template>
                    </Column>
                    </DataTable>
            </template>
            </Card>
          
          
</template>

<script>
import {mapGetters} from 'vuex'
    export default {
        name: 'ViewCustomScripts',
        data() {
            return {
                loadingFlag: true,
                loadingItem: false,
                // customScripts:[
                //     {
                //         name: 'theme-color',
                //         code: '<meta name="theme-color" content="#8E24AA" />',
                //         author: 'Admin',
                //         date: new Date(),
                //         locate: 'head'
                //     },
                // ],
            }
        },
        async mounted(){
          if (this.customScripts.length === 0) {
            await this.$store.dispatch('customScript/awaitGetScripts')
          }
            this.loadingFlag = false;
        },
        computed:{
          ...mapGetters({
            customScripts: 'customScript/getScripts',
          })
        },
        methods:{
            addScript(){

            },
            toEditScript(script){
              this.$router.push({ name: 'EditCustomScript', params: { id: script.id }});
              // localStorage.setItem('CustomScript', JSON.stringify(script));
            },
            async toToggleScript(script){
              script.loadingItem = true;
              // const scriptId = script;
              this.loadingItem = true;
              const res = await this.$store.dispatch('customScript/updateCustomScript', script);
              if (res) {
                this.$toast.add({
                  severity: 'success', summary: this.$t('updated_script'), life: 4000
                })
                this.$store.dispatch('customScript/awaitGetScripts');
              } else{
                this.$toast.add({
                  severity: 'error', summary: this.$t("just_error"), life: 4000
                })
              }
              script.loadingItem = false;
              this.loadingItem = false;
            },
            async toDeleteScript(script){
              script.loadingItem = true;
              const scriptId = script.id;
              this.loadingItem = true;
              const res = await this.$store.dispatch('customScript/deleteCustomScript', scriptId);
              if (res) {
                this.$toast.add({
                  severity: 'success', summary: this.$t('deleted_script'), life: 4000
                })
                this.$store.dispatch('customScript/awaitGetScripts');
              } else{
                this.$toast.add({
                  severity: 'error', summary: this.$t("just_error"), life: 4000
                })
              }
              script.loadingItem = false;
              this.loadingItem = false;
            },
            getIcon(nameLocate){
                switch(nameLocate){
                    case "head":
                        return 'mdi-menu-up'
                    case "footer":
                        return 'mdi-menu-down'
                }
            },
        },
        beforeRouteEnter (to, from, next) {
            next(vm =>
            {
                vm.$store.commit('setPageName', 'custom_js')
            }
            )
        }
    }
</script>

<style lang="scss" scoped>

</style>